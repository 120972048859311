.relation_jg_dialog .el-dialog--center .el-dialog__body {
  padding: 25px 20px 30px;
}
.relation_jg_dialog .el-table--border {
  border-bottom: 1px solid #ebeef5;
}
.relation_jg_dialog .el-table--border th {
  border-right: 1px solid #ebeef5;
}
.relation_jg_dialog .table_box {
  width: 42%;
}
.relation_jg_dialog .table_box .table_box_top {
  margin-bottom: 10px;
}
.relation_jg_dialog .table_box .list_top {
  padding: 15px;
  text-align: center;
  border: 1px solid #ebeef5;
  border-bottom: 0;
}
.relation_jg_dialog .table_box .list_search {
  border: 1px solid #ebeef5;
  border-top: 0;
  border-bottom: 0;
  padding: 0 10px 15px;
}
.relation_jg_dialog .btn_arrow {
  padding: 1.25rem;
}
.relation_jg_dialog .btn_arrow > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.relation_jg_dialog .relation_page .el-pagination__sizes .el-select {
  top: -3px;
}
.form-item-nomargin {
  margin-bottom: 5px;
}
.form-item-color .el-form-item__label {
  color: #ff6e3b;
}
.orderDetail .order_stu {
  padding: 10px;
  background-color: #ffffcc;
  border: 1px solid #ffcc00;
  color: #ff3300;
}
.orderDetail .order_form div.df {
  padding: 8px 0;
}
.orderDetail .order_form div.df span:first-child {
  width: 6rem;
  text-align: right;
  margin-right: 8px;
}
